<template>
  <div class="company-chart">
    <div class="company-chart-header">
      <template v-if="type === 'rebased'">
        <p>%</p>
        <p>ㅤ</p>
      </template>
      <template v-else>
        <!-- <p v-if="leftAxis === 'percentage'" style="padding-left: 20px;">%</p> -->
        <p v-if="leftAxis==''">US$</p>
        <p v-if="leftAxis=='ratio'">US$ Per<br>Share</p>
        <p v-if="leftAxis==='percentage'">%</p>
        <p v-if="!leftAxis"></p>
        <p v-if="rightAxis === 'ratio'">US$ Per<br>Share</p>
        <p
        style="
          padding-right: 10px;
        "
        v-else-if="rightAxis === 'percentage'">
          %
        </p>
        <!-- <p v-if="data.datasets.some((row) => row.yAxisID === 'y-right')">
          US$ Per<br>Share</p> -->
      </template>
    </div>
    <div class="company-chart-wrapper">
      <div class="company-chart-wrapper-preloader" v-if="loading">
        <VueSpinner size="40" color="var(--theme-link-color)" />
      </div>
      <Line :data="data" :options="options" v-if="!loading" />
    </div>
    <ul class="company-chart-legend">
      <li
        v-for="(item, index) in data.datasets"
        :key="index"
      >
        <span :style="{background: item.borderColor as string}"></span>
        <p>{{ item.label }}</p>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  computed,
} from 'vue';
import { useStore } from 'vuex';

import { Line } from 'vue-chartjs';
import { VueSpinner } from 'vue3-spinners';
import { ChartField } from '@/components/ui/table-column/types';

import { chartFields, companyChartOptions, initialChartParams } from '@/data/chart';
import { percentage } from '@/services/renderers';

import { CompanyController } from '@/controllers/company/types';

import { useChart } from './controller';

export default defineComponent({
  name: 'CompanyChart',
  components: {
    Line,
    VueSpinner,
  },
  props: {
    type: {
      type: String,
      enum: ['rebased', 'absolut'],
      default: 'absolut',
    },
    period: {
      type: String,
      enum: ['10Y', '5Y', '1Y', '6M', '1M', '5D', '1D'],
      default: '10Y',
    },
    company: {
      type: Object as PropType<CompanyController>,
      required: true,
    },
    rows: {
      type: Object as PropType<string[]>,
      required: false,
    },
  },
  setup(props) {
    const store = useStore();
    const type = computed(() => props.type);
    const period = computed(() => props.period);
    const rightAxis = computed(() => {
      if (props.rows?.length && props.rows?.length > 0) {
        const rightRows: ChartField[] = props.rows?.map((key: string) => {
          const chartRow: any = chartFields.find((field: ChartField) => field.key === key);
          if (chartRow && chartRow.axis === 'y-right') {
            return chartRow;
          }
          return undefined;
        }).filter(Boolean);
        if (!rightRows || rightRows.length === 0) {
          return null;
        }
        if (rightRows.length === 1) {
          return rightRows[0].handler;
        }
        const isRatio = !rightRows.every((row: ChartField) => row.handler === 'percentage');

        if (isRatio) {
          return 'ratio';
        }
        return rightRows?.length > 0 && 'percentage';
      }
      return '';
    });
    const leftAxis = computed(() => {
      if (props.rows?.length && props.rows?.length > 0) {
        const leftRows: ChartField[] = props.rows?.map((key: string) => {
          const chartRow: any = chartFields.find((field: ChartField) => field.key === key);
          if (chartRow && chartRow.axis === 'y-left') {
            return chartRow;
          }
          return undefined;
        }).filter(Boolean);
        if (!leftRows || leftRows.length === 0) {
          return null;
        }

        if (leftRows.length === 1 && leftRows[0].handler !== undefined) {
          return leftRows[0].handler;
        }
        const isRatio = !leftRows.every((row: ChartField) => row.handler === 'percentage');
        if (isRatio) {
          return 'ratio';
        }
        return leftRows?.length > 0 && 'percentage';
      }
      return null;
    });
    const { loading, data, options } = useChart(
      type,
      period,
      companyChartOptions,
      props.company,
    );
    return {
      data,
      loading,
      options,
      leftAxis,
      rightAxis,
    };
  },
});
</script>

<style>
.company-chart {
  max-width: 100%;
  width: 100%;
}

.company-chart > .company-chart-wrapper {
  position: relative;
  height: 420px;
}

.company-chart > .company-chart-wrapper > .company-chart-wrapper-preloader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background: var(--theme-text-color-contrast);
}

.company-chart > .company-chart-wrapper > canvas {
  max-width: 100% !important;
  width: 100% !important;
}

.company-chart > .company-chart-legend {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  list-style-type: none;
  padding: 0 20px;
  height: 16px;
}

.company-chart > .company-chart-legend > li {
  display: flex;
  align-items: center;
  gap: 8px;
}

.company-chart > .company-chart-legend > li > span {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.company-chart > .company-chart-legend > li > p {
  font-size: 12px;
  font-weight: 600;
  color: var(--theme-text-color-2);
}

.company-chart > .company-chart-header {
  padding: 0 0 0 15px;
  flex: 0 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.company-chart > .company-chart-header > p {
  font-size: 10px;
  font-weight: 400;
  color: var(--theme-text-color-2);
  text-transform: uppercase;
}
</style>
