import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-57a6cebc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueSpinner = _resolveComponent("VueSpinner")!

  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["button", {
      'button__transparent': _ctx.type === 'transparent',
      'button__secondary': _ctx.type === 'secondary',
      'button__small': _ctx.size === 'small',
      'button__disabled': _ctx.visuallyDisabled,
      'tooltip': _ctx.tooltipText,
    }]),
    disabled: _ctx.loading || _ctx.disabled
  }, [
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_VueSpinner, {
          key: 0,
          color: "var(--theme-text-color-contrast)"
        }))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    (_ctx.tooltipText)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          class: "tooltiptext",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickTooltip && _ctx.onClickTooltip(...args)))
        }, _toDisplayString(_ctx.tooltipText), 1))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}