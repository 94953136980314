import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f5947c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "dropdown-pannel",
  ref: "dropdownContent"
}
const _hoisted_2 = { class: "dropdown-value" }
const _hoisted_3 = {
  key: 0,
  class: "tooltiptext"
}
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "dropdown-button tooltip",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args)), ["self"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      (_ctx.tooltipText)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.tooltipText), 1))
        : _createCommentVNode("", true)
    ]),
    (_ctx.dropdownMenu)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "dropdown-menu",
          style: _normalizeStyle({
      width: _ctx.menuWidth ? _ctx.menuWidth : '286px',
      transform: _ctx.menuTransform ? _ctx.menuTransform : 'translateX(-20%)',
      right: _ctx.menuRight ? _ctx.menuRight : 'none',
      left: _ctx.menuLeft ? _ctx.menuLeft : 'none',
      gap: _ctx.gap ? _ctx.gap : 'none',
      display: 'flex',
      flexDirection: 'column',
    })
        }, [
          _renderSlot(_ctx.$slots, "content", {}, () => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (item, index) => {
              return (_openBlock(), _createElementBlock("button", {
                key: index,
                class: _normalizeClass(["dropdown-menu-button", {
              'dropdown-menu-button__active'
                :_ctx.model?.key === item.key
            }]),
                onClick: ($event: any) => (_ctx.selectValue(item))
              }, _toDisplayString(item.title), 11, _hoisted_4))
            }), 128))
          ], true)
        ], 4))
      : _createCommentVNode("", true)
  ], 512))
}