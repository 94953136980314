import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/arrow-down.svg'


const _withScopeId = n => (_pushScopeId("data-v-3a4ca451"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "collapse-dropdown-icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("button", {
      class: _normalizeClass(["collapse-item", { 'active': _ctx.active }]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleCollapse && _ctx.toggleCollapse(...args))),
      style: _normalizeStyle({ 'width': _ctx.btnWidth ? _ctx.btnWidth : '144px',
    'background': _ctx.bgColor ? _ctx.bgColor : '#fff'
   })
    }, [
      _createElementVNode("span", null, _toDisplayString(_ctx.text), 1),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("img", {
          src: _imports_0,
          alt: "Arrow",
          class: _normalizeClass({
        'reversed-arrow': _ctx.active
      })
        }, null, 2)
      ])
    ], 6),
    (_ctx.active)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 }, undefined, true)
      : _createCommentVNode("", true)
  ], 64))
}