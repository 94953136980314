import {
  ref,
  computed,
  reactive,
  watch,
  Ref,
} from 'vue';

import {
  ChartData as ChartJSData,
  ChartOptions,
  ChartDataset,
} from 'chart.js';

import { CompanyController } from '@/controllers/company/types';
import { Chart, ChartData } from '@/rest-api/companies/assets';
import { chartFields, companyChartOptions } from '@/data/chart';
import { ChartField } from '@/components/ui/table-column/types';
import { useStore } from 'vuex';

export const useChart = ( // eslint-disable-line
  type: Ref<string>,
  period: Ref<string>,
  initialOptions: ChartOptions,
  company: CompanyController,
) => {
  const store = useStore();
  const loading = ref(true);
  const reactiveDataset: ChartDataset<'line'>[] = reactive([]);
  const data: ChartJSData<'line'> = {
    labels: [],
    datasets: [],
  };

  const options = computed(() => {
    const opt: ChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          displayColors: false,
          backgroundColor: 'rgba(0, 0, 0, 0.65)',
          titleFont: {
            size: 12,
            family: 'Gilroy',
          },
          bodyFont: {
            size: 12,
            family: 'Gilroy',
            style: 'normal',
          },
        },
      },
      interaction: {
        intersect: false,
        mode: 'index',
      },
      scales: {
        'y-right': {
          type: 'linear',
          position: 'right',
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
          ticks: {
            display: false,
            padding: 10,
            color: '#808080',
            font: {
              size: 10,
              family: 'Gilroy',
              style: 'normal',
              lineHeight: 2,
            },
          },
        },
        'y-left': {
          type: 'linear',
          position: 'left',
          grid: {
            display: true,
            drawOnChartArea: true,
            drawTicks: false,
          },
          border: {
            display: false,
          },
          ticks: {
            display: false,
            padding: 10,
            color: '#808080',
            font: {
              size: 10,
              family: 'Gilroy',
              style: 'normal',
              lineHeight: 2,
            },
          },
        },
        x: {
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
          ticks: {
            display: true,
            color: '#808080',
            padding: 20,
            font: {
              size: 10,
              family: 'Gilroy',
              style: 'normal',
              lineHeight: 2,
            },
          },
        },
      },
    };

    if (!opt.scales || !opt.scales['y-right'] || !opt.scales['y-right'].ticks) {
      return opt;
    }

    if (reactiveDataset.some((row) => row.yAxisID === 'y-right')) {
      opt.scales['y-right'].ticks.display = true;
    }
    if (!opt.scales || !opt.scales['y-left'] || !opt.scales['y-left'].ticks) {
      return opt;
    }
    if (reactiveDataset.some((row) => row.yAxisID === 'y-left')) {
      opt.scales['y-left'].ticks.display = true;
    }

    return opt;
  });

  const chartParams = computed(() => store.state.application.chartParams);

  const createDataset = (label: string, values: ChartData[], color: string, axis: string) => {
    const dataset: any[] = [];
    if (!data.labels?.length) return;

    for (let i = 0; i < data.labels.length; i += 1) {
      const aLabel = data.labels[i];
      const filteredValues = values.filter((value) => value.label === aLabel);
      const value = filteredValues.length > 0 ? filteredValues[0].value : null;
      if (type.value === 'rebased') {
        if (i === 0) {
          dataset.push(0);
        } else if (filteredValues.length === 0) dataset.push(null);
        else if (filteredValues.length > 0) {
          if (value) dataset.push(((value) / values[0].value - 1) * 100);
          else dataset.push(null);
        }
      } else {
        dataset.push(value);
      }
    }

    const object = {
      label,
      data: dataset,
      yAxisID: axis,
      borderWidth: 3,
      pointRadius: 0,
      borderColor: color,
      fill: true,
      backgroundColor: 'transparent',
    };
    if (dataset.length > 0) {
      reactiveDataset.push(object);
      data.datasets?.push(object);
    }
  };

  const update = () => {
    loading.value = true;

    data.labels = [];
    data.datasets = [];
    reactiveDataset.splice(0);

    company.getChartData(period.value).then((payload) => {
      console.log('************************ here is chart data ********************');
      console.log(payload);
      Object.keys(payload).forEach((key) => {
        const chartKey = key as keyof Chart;
        // if (chartKey === 'stockPrice') {
        //   for (let i = 0; i < payload[chartKey].length; i += 1) {
        //     const values = payload[chartKey];
        //     data.labels?.push(values[i].label);
        //   }
        // }
        if (!chartParams.value?.includes(chartKey.toString())) return;
        for (let i = 0; i < payload[chartKey].length; i += 1) {
          const values = payload[chartKey];
          if (!data.labels?.includes(values[i].label)) {
            data.labels?.push(values[i].label);
          }
        }
      });
      // if (period.value === '10Y' || period.value === '5Y' ||
      // period.value === '1Y') data.labels?.sort();
      // console.log(chartParams.value);
      Object.keys(payload).forEach((key) => {
        const chartKey = key as keyof Chart;
        // console.log(chartKey);
        if (!chartParams.value?.includes(chartKey.toString())) return;
        let label = '';
        let color = '';
        let axis = 'y-right';
        chartFields.forEach((field: ChartField) => {
          if (chartKey === field.key) {
            label = field.name;
            color = field.color;
            axis = type.value === 'rebased' ? 'y-left' : field.axis;
          }
        });
        // console.log(label);
        // console.log(payload[chartKey]);
        createDataset(label, payload[chartKey], color, axis);
      });
    }).finally(() => {
      loading.value = false;
    });
  };

  const computedData = computed(() => data);

  watch(() => [period.value, type.value, chartParams.value.length], update);

  update();

  return {
    loading,
    options,
    data: computedData,
  };
};
